<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4>الطاولات</h4>
        <v-spacer></v-spacer>
        <v-btn @click="open_dialog()" color="primary">اضافة</v-btn>
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="tables"
        :headers="headers"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small icon @click="get_delete_id(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
          <v-btn small icon @click="edit_table(item)">
            <v-icon small>edit</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>اضافة طاولة</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form ref="form">
            <v-text-field
              :rules="[(v) => !!v || 'حقل مطلوب']"
              v-model="table_name"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                رقم الطاولة
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="new_table()">حفظ</v-btn>
          <v-btn @click="close_dialog()" color="red" text>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog max-width="500" v-model="delete_dialog">
      <v-card>
        <v-card-text class="pt-5">
          <h2 class="text-center py-5">         حذف الطاولة  ؟ </h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="delete_table()">حذف</v-btn>
          <v-btn color="error" text>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف الطاولة</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!--/ container -->
</template>

<script>
import Order from "@/components/orders/order.vue";
export default {
  name: "tables",

  data() {
    return {
      delete_dialog:false,
      dialog: false,
      table_id:null,
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      tables: [],
      table_name: null,
      headers: [
        {
          value: "table_name",
          text: "اسم الطاولة",
          align: "center",
        },
        {
          value: "table_code",
          text: "كود الطاولة",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
      files: [],
    };
  },
  components: {
    Order,
  },
  methods: {
    get_tables() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "tables",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.tables = Object.assign([], res.data.data);
        })
    },
    open_dialog() {
      this.dialog = true;
      this.table_name = null;
    },
    edit_table(item) {
      this.table_name = item.table_name;
      this.table_id = item.id;
      this.dialog = true;
      this.edit = true;
    },
    close_dialog() {
      this.table_name = null;
      this.dialog = false;
      this.table_id  = null;
      this.$refs.form.resetValidation();
    },
    new_table() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.table_id ? `tables/${this.table_id}` : 'tables', 
              method: this.table_id  ? 'put' :"post",
            },
            data:{
              table_name:this.table_name
            }
          })
          .then((res) => {
            this.get_tables();
            this.close_dialog();
          })
      }
    },
    get_delete_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_table() {
      this.$store.dispatch("public__request", {
        config:{
          url:`tables/${this.delete_id}`,
          method:"delete"
        }
      }).then(res=> {
        this.snackbar_success = true;
        this.delete_id = null;
        this.delete_dialog = false;
        this.get_tables();
      })
    }
  },
  mounted() {
    this.get_tables();
  },
};
</script>
